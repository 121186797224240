
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "buildId": "255bb9f2e2487305c318100053f0fbb5b4e748e3",
  "nuxt": {
    "buildId": "2eabeebe-641d-44b1-9d8f-0b1ed2d01692"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
